import axios from "axios";

import { getCookie } from "./cookie";

export const getDailyOrderList = async(type, date) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.get('https://api.madinbakery.com/order/daily', {
                params: {token: token,type:type,date: date},
            });
            return  response.data.orderList;
        } catch(err) {
            console.log(err);
        }
    }
}

export const getRecentMonthlyOrderList = async(type) => {
    const token = getCookie('token');

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth() + 1;
    
    let nextYear;
    let nextMonth;
    if (todayMonth < 12) {
        nextYear = todayYear;
        nextMonth = todayMonth + 1;
    } else{
        nextYear = todayYear + 1;
        nextMonth = 1;
    }

    if (token) {
        try {
            const responseTodayMonth = await axios.get('https://api.madinbakery.com/order/monthly', {
                params: {token: token, type: type, year: todayYear, month: todayMonth},
            });
            const todayMonthOrderList = responseTodayMonth.data.orderList;

            const responseNextMonth = await axios.get('https://api.madinbakery.com/order/monthly', {
                params: {token: token, type: type, year: nextYear, month: nextMonth},
            });
            const nextMonthOrderList = responseNextMonth.data.orderList;

            return [ ...todayMonthOrderList, ... nextMonthOrderList]
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateOrderStatus = async(id,status) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/order/'+id,{
                "token": token,
                "status": status,
            });
            return response.data.updatedOrder;
        } catch(err) {
            console.log(err);
        }
    }
}

export const updateManagerOrderInfo = async(id,managerOrderInfo) => {
    const token = getCookie('token');

    if (token) {
        try {
            const response = await axios.patch('https://api.madinbakery.com/order/'+id,{
                "token": token,
                "managerOrderInfo": managerOrderInfo,
            });
            return response.data.updatedOrder;
        } catch(err) {
            console.log(err);
        }
    }
}


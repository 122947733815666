import './OrderDescriptionEditModal.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import Modal from '../Modal/Modal';
import QuadrangleButton from '../QuadrangleButton/QuadrangleButton';

import { setManagerOrderDescription } from '../../_reducers/user';

export default function OrderDescriptionEditModal(props) {
    const dispatch = useDispatch();

    const componentId = 'order-description-edit-modal';

    const [description, setDescription] = useState(props.description);
    const [isInitialized, setInitialized] = useState(false);

    const initializeInput = () => {
        setDescription(props.description);
    }

    if (!isInitialized & !description && props.description) {
        initializeInput();
        setInitialized(true);
    }

    const updateEvent = () => {
        dispatch(setManagerOrderDescription({description:description}));
    }

    let isEditable = (description);

    let EditButton;
    if (isEditable) {
        EditButton =
            <form method="dialog" className='order-description-edit-modal-close-button-container'>
                <QuadrangleButton
                    paddingTop={12}
                    paddingBottom={12}
                    borderRadius={14}
                    text='수정하기'
                    clickEvent={updateEvent}/>
            </form>
    }

    return (
        <Modal id={componentId} closeEvent={initializeInput}>
            <div className='order-description-edit-modal'>
                <span className='order-description-edit-modal-title'>주문 상세</span>
                <textarea className='order-description-edit-modal-textarea' 
                    placeholder='주문 상세를 적어주세요.'
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}></textarea>
                <div style={{'minHeight':'50px'}}></div>

                {EditButton}
            </div>
        </Modal>
    )
}

OrderDescriptionEditModal.defaultProps = {
    description: '주문 상세를 적어주세요.',
}
import './OrderMethod.css';

import { useDispatch, useSelector } from 'react-redux';

import { setManagerOrderOrderMethod } from '../../_reducers/user';

export default function OrderMethod(props) {
    const user = useSelector(state => state.user);

    return (
        <div className='order-method'>
            <span className='order-method-title'>주문</span>
            <div className='order-method-buttons'>
                <OrderMethodButton text='DM' isOn={user.managerOrderInfo.orderMethod === 'DM'}/>
                <OrderMethodButton text='매장' isOn={user.managerOrderInfo.orderMethod === '매장'}/>
                <OrderMethodButton text='카톡채널' isOn={user.managerOrderInfo.orderMethod === '카톡채널'}/>
            </div>
        </div>
    )
}

function OrderMethodButton(props) {
    const dispatch = useDispatch();

    const orderMethodButtonClickEvent = (text) => {
        dispatch(setManagerOrderOrderMethod({orderMethod: text}))
    }

    if (props.isOn) {
        return (
            <button className='order-method-button on'>
                <span className='order-method-button-text on'>{props.text}</span>
            </button>
        )
    } else {
        return (
            <button className='order-method-button off' onClick={() => orderMethodButtonClickEvent(props.text)}>
                <span className='order-method-button-text off'>{props.text}</span>
            </button>
        )
    }
    
}

OrderMethod.defaultProps = {

}

OrderMethodButton.defaultProps = {
    isOn: false,
    text: "버튼",
}
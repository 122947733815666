import UnitContentsContainer from "../UnitContentsContainer/UnitContentsContainer";
import PayMethod from "../PayMethod/PayMethod";
import OrderMethod from "../OrderMethod/OrderMethod";
import OrderDescription from "../OrderDescription/OrderDescription";

export default function ManagerOrderPaymentContentsContainer(props) {
    return (
        <UnitContentsContainer title='결제 정보'>
            <div style={{'minHeight':'7px'}}></div>
            <OrderMethod/>
            <PayMethod/>
            <OrderDescription/>
            <div style={{'minHeight':'7px'}}></div>
            <div style={{'width':'100%','minHeight':'1px','backgroundColor':'#eee'}}></div>
        </UnitContentsContainer>
    )
}

ManagerOrderPaymentContentsContainer.defaultProps = {

}
import './Order.css';

import { useDispatch } from 'react-redux';

import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';
import OrderPickupTimeChangeModal from '../OrderPickupTimeChangeModal/OrderPickupTimeChangeModal';
import OrderPickupTimeChangeMobileModal from '../OrderPickupTimeChangeMobileModal/OrderPickupTimeChangeMobileModal';

import { updateOrderStatus, getDailyOrderList, getRecentMonthlyOrderList, updateManagerOrderInfo } from '../../functions/manage';
import { saveOrders, saveRecentOrders } from '../../_reducers/order';
import { cancelManagerOrder } from '../../functions/order';
import { useState } from 'react';

export default function Order(props) {
    const dispatch = useDispatch();  

    const [isPickupTimeChangeMobileModalOn, setPickupTimeChangeMobileModalOn] = useState(false);
    const openPickupTimeChangeMobileModal = () => {
        setPickupTimeChangeMobileModalOn(true);
    }
    const closePickupTimeChangeMobileModal = () => {
        setPickupTimeChangeMobileModalOn(false);
    }
    const showOrderPickupTimeChangeModal = () => {
        if (window.innerWidth > 700) {
            const OrderPickupTimeChangeModal = document.getElementById('order-pickup-time-change-modal-' + props.id);
            OrderPickupTimeChangeModal.showModal();
        } else {
            openPickupTimeChangeMobileModal();
        }
    }
    
    const Items = props.items.map((item,index) => {
        let itemName;
        let itemPrice;
        if (item.option) {
            if (item.option.name !== 'basic') {
                itemName = item.name + ' ' + item.option.name;
                itemPrice = item.option.price;
            } else {
                itemName = item.name;  
                itemPrice = item.price;  
            }
        } else {
            itemName = item.name;
            itemPrice = item.price;  
        }
        if (item.isChecked) {
            return (
                <div className='order-contents-container' key={'order-item-'+index}>
                    <span className='order-items'>{itemName} x {item.quantity}</span>
                    <span className='order-items'>{Number(itemPrice).toLocaleString()}원</span>
                </div>
            )
        }
    })

    const StatusButtonClickEvent = (status) => {
        updateOrderStatus(props.id,status).then(() => {
            getDailyOrderList(props.type,props.date).then((orderList) => {
                dispatch(saveOrders(orderList));
            })
            getRecentMonthlyOrderList(props.type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        })
    }

    let StatusButtons;
    let CancelNotification;
    if (props.status === '결제취소') {
        CancelNotification = 
            <div className='order-canceled-notification-container'>
                <h3 className='order-canceled-notification'>
                    취소된 주문입니다.
                </h3>
            </div>
    } else {
        if (props.type === 'delivery') {
            StatusButtons = 
            <div className='order-status-buttons'>
                <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='배송중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                <StatusButton text='배송완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
            </div>
        } else if (props.type === 'pickup') {
            if (props.managerOrderInfo) {
                if (props.managerOrderInfo.isManagerOrder) {
                    StatusButtons = 
                        <div className='order-status-buttons'>
                            <StatusButton text='미결제' status={props.status} clickEvent={StatusButtonClickEvent} isDeactivated={true}/>
                            <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent} isDeactivated={true}/>
                            <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                            <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                        </div>
                } else {
                    StatusButtons = 
                        <div className='order-status-buttons'>
                            <StatusButton text='미결제' status={props.status} clickEvent={StatusButtonClickEvent}/>
                            <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                            <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                            <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                        </div>
                }
            } else {
                StatusButtons = 
                    <div className='order-status-buttons'>
                        <StatusButton text='미결제' status={props.status} clickEvent={StatusButtonClickEvent}/>
                        <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                        <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                        <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    </div>
            }
        } else if (props.type === 'present') {
            StatusButtons = 
                <div className='order-status-buttons'>
                    <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='배송중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='배송완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                </div>
        } else {
            StatusButtons = 
                <div className='order-status-buttons'>
                    <StatusButton text='미결제' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='결제완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='상품준비중' status={props.status} clickEvent={StatusButtonClickEvent}/>
                    <StatusButton text='픽업완료' status={props.status} clickEvent={StatusButtonClickEvent}/>
                </div>
        }
    }

    let ShippingInfo;
    if ((props.type === 'present') || (props.type === 'delivery')) {
        ShippingInfo = 
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        배송지
                    </h3>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[주소] {props.address}</span>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[받는사람] {props.reciever}</span>
                </div>
                <div className='order-contents-container'>
                    <span className='order-items'>[전화번호] {props.phone}</span>
                </div>
            </div>
    }

    let year;
    let month;
    let day;
    let PickupTime;
    if (props.type === 'pickup') {
        year = props.pickupDate.split('-')[0];
        month = props.pickupDate.split('-')[1];
        day = props.pickupDate.split('-')[2];
        if (props.isDateNeeded) {
            PickupTime = 
            <div className='order-contents-container'>
                <h3 className='order-contents'>
                    픽업시간
                </h3>
                <h3 className='order-contents'>
                    {props.pickupDate.split('-')[0]}년 {props.pickupDate.split('-')[1]}월 {props.pickupDate.split('-')[2]}일 {props.pickupTime}
                </h3>
            </div>
        } else {
            PickupTime = 
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        픽업시간
                    </h3>
                    <h3 className='order-contents'>
                        {props.pickupTime}
                    </h3>
                </div>
        }
    }

    let orderIdConatiner;
    let customerName;
    let customerPhone;
    let notPayedNotification;
    let managerOrderButtons;
    let payMethods;
    let orderMethods;
    const openOrderCancelCheckModal = () => {
        const OrderCancelCheckModal = document.getElementById('order-cancel-modal-' + props.id) ;
        OrderCancelCheckModal.showModal();
    }
    const orderCancelEvent = async() => {
        cancelManagerOrder(props.id).then(() => {
            getDailyOrderList(props.type,props.date).then((orderList) => {
                dispatch(saveOrders(orderList));
            })
            getRecentMonthlyOrderList(props.type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        });
    }
    
    const payMethodButtonClickEvent = (payMethod) => {
        let managerOrderInfo = props.managerOrderInfo;
        managerOrderInfo.payMethod = payMethod;
        if (payMethod !== '미결제') {
            managerOrderInfo.isPayed = true;
        } else {
            managerOrderInfo.isPayed = false;
        }
        updateManagerOrderInfo(props.id,managerOrderInfo).then(() => {
            if ((payMethod === '신용카드' || payMethod === '계좌이체') && (props.status === '미결제')) {
                updateOrderStatus(props.id,'결제완료').then(() => {
                    getDailyOrderList(props.type,props.date).then((orderList) => {
                        dispatch(saveOrders(orderList));
                    })
                    getRecentMonthlyOrderList(props.type).then(orderList => {
                        dispatch(saveRecentOrders(orderList));
                    })
               });
            }
            else if (payMethod === '미결제' && props.status !== '결제취소') {
                updateOrderStatus(props.id,'미결제').then(() => {
                    getDailyOrderList(props.type,props.date).then((orderList) => {
                        dispatch(saveOrders(orderList));
                    })
                    getRecentMonthlyOrderList(props.type).then(orderList => {
                        dispatch(saveRecentOrders(orderList));
                    })
               });
            } else {
                getDailyOrderList(props.type,props.date).then((orderList) => {
                    dispatch(saveOrders(orderList));
                })
                getRecentMonthlyOrderList(props.type).then(orderList => {
                    dispatch(saveRecentOrders(orderList));
                })
            }  
        })
    }
    const orderMethodButtonClickEvent = (orderMethod) => {
        let managerOrderInfo = props.managerOrderInfo;
        managerOrderInfo.orderMethod = orderMethod;        
        updateManagerOrderInfo(props.id,managerOrderInfo).then(() => {
            getDailyOrderList(props.type,props.date).then((orderList) => {
                dispatch(saveOrders(orderList));
            })
            getRecentMonthlyOrderList(props.type).then(orderList => {
                dispatch(saveRecentOrders(orderList));
            })
        })
    }
    if (props.managerOrderInfo) {
        if (props.managerOrderInfo.isManagerOrder) {
            orderIdConatiner =
                <div className='order-id-container'>
                    <span className='order-id'>운영자 주문 {props.id}</span>
                </div>
            customerName =
                <div className='order-contents-container'>
                    <span className='order-items'>[이름] {props.managerOrderInfo.name}</span>
                </div>
            if (props.status !== '결제취소') {
                customerPhone = 
                    <div className='order-contents-container'>
                        <span className='order-items'>[전화번호] {props.managerOrderInfo.phone}</span>
                    </div>
                payMethods = 
                    <div className='order-contents-container'>
                        <h3 className='order-contents'>
                            결제
                        </h3>
                        <div className='order-pay-method-buttons'>
                            <OrderPayMethodButton text='미결제' isOn={props.managerOrderInfo.payMethod ==='미결제'}
                                                clickEvent={payMethodButtonClickEvent}/>
                            <OrderPayMethodButton text='신용카드' isOn={props.managerOrderInfo.payMethod ==='신용카드'}
                                                clickEvent={payMethodButtonClickEvent}/>
                            <OrderPayMethodButton text='계좌이체' isOn={props.managerOrderInfo.payMethod ==='계좌이체'}
                                                clickEvent={payMethodButtonClickEvent}/>
                        </div>
                    </div>
                orderMethods =
                    <div className='order-contents-container'>
                        <h3 className='order-contents'>
                            주문
                        </h3>
                        <div className='order-pay-method-buttons'>
                            <OrderPayMethodButton text='DM' isOn={props.managerOrderInfo.orderMethod ==='DM'}
                                                clickEvent={orderMethodButtonClickEvent}/>
                            <OrderPayMethodButton text='매장' isOn={props.managerOrderInfo.orderMethod ==='매장'}
                                                clickEvent={orderMethodButtonClickEvent}/>
                            <OrderPayMethodButton text='카톡채널' isOn={props.managerOrderInfo.orderMethod ==='카톡채널'}
                                                clickEvent={orderMethodButtonClickEvent}/>
                        </div>
                    </div>
                managerOrderButtons = 
                    <div className='order-contents-container space'>
                        <button className='order-manager-button' onClick={() => openOrderCancelCheckModal()}>
                            <span className='order-manager-button-text'>주문 취소하기</span>
                        </button>
                        <button className='order-manager-button' onClick={() => showOrderPickupTimeChangeModal()}>
                            <span className='order-manager-button-text'>픽업시간 변경하기</span>
                        </button>
                    </div>
            }
            if (!props.managerOrderInfo.isPayed && props.status === '미결제') {
                notPayedNotification =
                    <div className='order-contents-container center'>
                        <span className='order-items warning'>결제가 완료되지 않았습니다. 결제를 진행해주세요.</span>
                    </div>
            }
        } else {
            orderIdConatiner =
                <div className='order-id-container'>
                    <span className='order-id'>주문번호 {props.id}</span>
                </div>
            customerName =
                <div className='order-contents-container'>
                    <span className='order-items'>[이름] {props.customer.name}</span>
                </div>
            customerPhone = 
                <div className='order-contents-container'>
                    <span className='order-items'>[전화번호] {props.customer.phone}</span>
                </div>
        }
    } else {
        orderIdConatiner =
            <div className='order-id-container'>
                <span className='order-id'>주문번호 {props.id}</span>
            </div>
        customerName =
            <div className='order-contents-container'>
                <span className='order-items'>[이름] {props.customer.name}</span>
            </div>
        customerPhone = 
            <div className='order-contents-container'>
                <span className='order-items'>[전화번호] {props.customer.phone}</span>
            </div>
    }    

    return (
        <div className='order'>
            {orderIdConatiner}
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        주문고객
                    </h3>
                </div>
                {customerName}
                {customerPhone}
            </div>
            <div className='order-items-container'>
                <div className='order-contents-container'>
                    <h3 className='order-contents'>
                        주문한 메뉴
                    </h3>
                </div>
                {Items}
            </div>
            <div className='order-contents-container'>
                <h3 className='order-contents'>
                    총 가격
                </h3>
                <h3 className='order-contents'>
                    {props.totalPrice.toLocaleString()}원
                </h3>
            </div>
            {ShippingInfo}
            {PickupTime}
            {payMethods}
            {orderMethods}
            {notPayedNotification}
            {managerOrderButtons}
            {StatusButtons}
            {CancelNotification}
            <DeleteCheckModal
                id={'order-cancel-modal-'+props.id}
                text='취소하시겠습니까?'
                cancelText='돌아가기'
                deleteText='주문취소'
                deleteEvent={orderCancelEvent}/>
            <OrderPickupTimeChangeModal
                id={props.id}
                year={year}
                month={month}
                date={props.date}
                day={day}/>
            <OrderPickupTimeChangeMobileModal
                id={props.id}
                isOn={isPickupTimeChangeMobileModalOn}
                closeEvent={closePickupTimeChangeMobileModal}
                year={year}
                month={month}
                date={props.date}
                day={day}/>
        </div>
    )
}

function StatusButton(props) {
    if (props.text === props.status) {
        return (
            <button className='order-status-button on'>
                <span className='order-status-button-text on'>{props.text}</span>
            </button>
        )
    } else {
        if (props.isDeactivated) {
            return (
                <button className='order-status-button deactivated'>
                    <span className='order-status-button-text deactivated'>{props.text}</span>
                </button>
            )
        } else {
            return (
                <button className='order-status-button off' onClick={() => props.clickEvent(props.text)}>
                    <span className='order-status-button-text off'>{props.text}</span>
                </button>
            )
        }
    }
}

function OrderPayMethodButton(props) {
    if (props.isOn) {
        return (
            <button className='order-pay-method-button on'>
                <span className='order-pay-method-button-text on'>{props.text}</span>
            </button>
        )
    } else {
        return (
            <button className='order-pay-method-button off'>
                <span className='order-pay-method-button-text off' onClick={() => props.clickEvent(props.text)}>{props.text}</span>
            </button>
        )
    }
}

const defaultClickEvent = () => {
    
}

Order.defaultProps = {
    isDateNeeded: false,
    id: '000',
    customer: '장원영',
    items: [
        {name: "레몬마들렌", quantity: 4, price: 2500},
    ],
    totalPrice: 14000,
    address: '경기도 화성시 시청로 102번길11 205동 1604호 (00406)',
    reciever: '장원영',
    phone: '010-0201-1102',
    status: '결제완료',
    clickEvent: defaultClickEvent,
    type: 'delivery',
    date: '2023-12-31',
    pickupDate: '2023-12-31',
    pickupTime: '15:00',
    presentDate: '2023-12-31',
    presentTime: '15:00',
}

StatusButton.defualtProps =  {
    clickEvent: defaultClickEvent,
}
import './OrderDescription.css';

import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import OrderDescriptionEditModal from '../OrderDescriptionEditModal/OrderDescriptionEditModal';
import OrderDescriptionEditMobileModal from '../OrderDescriptionEditMobileModal/OrderDescriptionEditMobileModal';

export default function OrderDescription(props) {
    const user = useSelector(state => state.user);

    const [isOrderEditMobileOn, setOrderEditMobileOn] = useState(false);

    const openOrderEditMobileModal = () => {
        setOrderEditMobileOn(true);
    }

    const closeOrderEditMobileModal = () => {
        setOrderEditMobileOn(false);
    }

    const showOrderEditModal = () => {
        if (window.innerWidth > 700) {
            const OrderEditModal = document.getElementById('order-description-edit-modal');
            OrderEditModal.showModal();
        } else {
            openOrderEditMobileModal();
        }
    }

    let description;
    if (!user.managerOrderInfo.description) {
        description = '주문 상세를 입력해주세요.';
    } else {
        description = user.managerOrderInfo.description;
    }

    return (
        <div className='order-description'>
            <div className='order-description-titles'>
                <span className='order-description-title'>주문 상세</span>
                <button className='order-description-update-button' onClick={() => showOrderEditModal()}>
                    <span className='order-description-update-button-text'>수정하기</span>
                </button>
            </div>
            <div className='order-description-contents'>
                <span className='order-description-contents-text'>🗒️ {description}</span>
            </div>
            <OrderDescriptionEditModal description={user.managerOrderInfo.description}/>
            <OrderDescriptionEditMobileModal 
                isOn={isOrderEditMobileOn} 
                description={user.managerOrderInfo.description}
                closeEvent={closeOrderEditMobileModal}/>
        </div>
    )
}

OrderDescription.defaultProps = {

}